// $ruler: 16px;
// $color-red: #303a74;
// $color-bg: #ebecf0;
// $color-shadow: #babecc;
// $color-white: #fff;

// .Category body,
// html {
//   background-color: $color-bg;
// }

// .Category body,
// p,
// input,
// select,
// textarea,
// button {
//   font-family: "Montserrat", sans-serif;
//   letter-spacing: -0.2px;
//   font-size: $ruler;
// }

// .Category div,
// p {
//   color: black;
//   text-shadow: 1px 1px 1px $color-white;
// }

// .Category form {
//   padding: $ruler;
//   width: $ruler * 20;
//   margin: 0 auto;
// }

// .Category .segment {
//   padding: $ruler * 2 0;
//   text-align: center;
// }

// // .Category button {
// //   border: 0;
// //   outline: 0;
// //   font-size: $ruler;
// //   border-radius: $ruler * 20;
// //   padding: $ruler;
// //   background-color: $color-bg;
// //   text-shadow: 1px 1px 0 $color-white;
// // }

// .Category label {
//   display: block;
//   margin-bottom: $ruler * 1.5;
//   width: 100%;
// }

// .Category button {
//   color: #61677c;
//   font-weight: bold;
//   box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
//   transition: all 0.2s ease-in-out;
//   cursor: pointer;
//   font-weight: 600;

//   &:hover {
//     box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
//   }

//   &:active {
//     box-shadow: inset 1px 1px 2px $color-shadow,
//       inset -1px -1px 2px $color-white;
//   }

//   .icon {
//     margin-right: $ruler/2;
//   }

//   &.unit {
//     border-radius: $ruler/2;
//     line-height: 0;
//     width: $ruler * 8;
//     height: $ruler * 3;
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0 $ruler/2;
//     font-size: $ruler * 1.2;
//     margin-bottom: 20px;

//     .icon {
//       margin-right: 0;
//     }
//   }

//   &.red {
//     display: block;
//     margin: 20px auto;
//     width: 350px;
//     color: $color-red;
//   }
// }

// .Category .input-group {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;

//   .Category label {
//     margin: 0;
//     flex: 1;
//   }
// }

// li {
//   list-style: none;
// }

// hr {
//   margin: 0;
//   border: 0;
//   background-color: #4a4a4a;
//   height: 1px;
// }

// .Category {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
//   margin-top: 20px;
//   font-size: 1rem;
//   font-weight: 600;
//   color: black;
// }
// .Category-items {
//   padding: 1.25rem;
//   &:hover {
//     cursor: pointer;
//     color: #b7b8bc;
//   }
// }

// @media screen and (max-width: 768px) {
//   .Categories {
//     display: flexbox;
//     flex-wrap: wrap;
//     width: 100%;
//     height: 10vh;
//     font-size: 20px;
//     padding: 1px;
//     margin-top: 5px;
//     justify-content: center;
//   }
//   .Category-List {
//     padding: 12px;
//   }
// }

.calculator {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/texture2.png");
  button {
    border: 0;
    outline: 0;
    margin-right: 60px;
    font-size: 20px;
    cursor: pointer;
  }
}

.paper {
  position: relative;
  width: 85%;
  aspect-ratio: 16/9;
  box-shadow: 10px 5px 4px -4px rgba(0, 0, 0, 0.582);
  background-image: url("../assets/texture1.jpg");
  padding: 50px;
}

.section1 {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.section2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5%;
}

.section3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
}
