* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.body {
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}

.slide {
  width: 1100vw;
  height: 100%;
  transition: 0.9s cubic-bezier(0.85, 0, 0.15, 1);
}

.slide1,
.slide2,
.slide3,
.slide4,
.slide5,
.slide6,
.slide7,
.slide8,
.slide9,
.slide10,
.slide11 {
  width: 100vw;
  height: 100%;
  float: left;
}

.slide1 {
  // background-color: red;
}

.slide2 {
  background-color: orange;
}

.slide3 {
  background-color: yellow;
}

.slide4 {
  background-color: green;
}
.slide5 {
  background-color: antiquewhite;
}
.slide6 {
  background-color: darkcyan;
}
.slide7 {
  background-color: deeppink;
}

.slide8 {
  background-color: rgb(95, 33, 66);
}

.slide9 {
  background-color: rgb(33, 47, 95);
}

.slide10 {
  background-color: rgb(86, 95, 33);
}
.slide11 {
  background-color: rgb(17, 231, 17);
}
.button_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
